import React from 'react';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import { useParams, Link } from "react-router-dom";
import { getStation } from '../components/DataJson'
import icon_map from './../assets/icon/icon_map.png'
import icon_site from './../assets/icon/icon_site.png'
import icon_webcam from './../assets/icon/webcam.png'
import icon_flag from './../assets/icon/flag.png'
import logo_famille_plus from './../assets/logo/Logo_LABEL_FamillePlus_RVB_2012.webp'
import logo_android from './../assets/logo/android.svg'
import logo_iphone from './../assets/logo/apple.svg'
import { Helmet } from 'react-helmet-async';
import SkiStationMap from '../components/StationMap';


export default function Detailstation() {
    let params = useParams();
    //let station = getStation(parseInt(params.id, 10));
    let station = getStation(params.nom);

    const cannonicalLink = "https://www.monchamois.com/Decouvrir-Station/" && station.nom;
    const metaDescription = "Toutes les informations pour savoir si " && station.nom && " est la station de vos rêves";

  // const address = station.adresse;

    return <div className='detailstation'>
        <Helmet>
                <title>{station.nom} : venez découvir la station </title>
                <meta name="description" content={metaDescription}></meta>
            <link rel="canonical" href={cannonicalLink}></link>
         </Helmet>

        <header className='header'>
            <div className='background'>
                <Navbar />
                <div className='textbox'>
                    <h1>{station.nom}, votre destination ski de rêve dans les {station.region} </h1>
                    <p>Découvrez tout ce que vous pouvez faire à {station.nom} cet hiver - du domaine skiable alpin à l'hébergement en passant par l'école de ski et l'accès à la station.</p>
                </div>
            </div>
        </header>

        <div className="section-detail_station">
        <div className="bloc-detail-station">
            <section className='bloc-rubrique'> 
                <div className='top_class'>
                    <h2>Localiser {station.nom}</h2>
                    <h4>Votre destination ski dans les {station.region}</h4>
                </div>
                <div className='det_info_station'>
        
                    <div className="det_stat_info">
                        <div className="det_stat_ss_element">
                            <div className='det_stat_ss_top'>
                                <h3>Localisation</h3>
                            </div>
                            <div className='det_stat_ss_block'>
                            <ul>
                                <li><div className="internalLink"><Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?regions=${station.region_ID}`} key={station.region}>{station.region}</Link></div> </li>
                                <li>{station.region_administrative}</li>
                                <li><div className="internalLink"><Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?departements=${station.departement_ID}`} key={station.departement}>{station.departement} ({station.departement_numero})</Link></div> </li>
                                {station.massif && <li>{station.massif}</li>}
                            </ul>
                            </div>
                        </div>
                        <div className="det_stat_ss_element">
                            
                            <div className='det_stat_ss_top'>
                                <h3>Office de tourisme</h3>
                            </div>
                            <div className='det_stat_ss_block'>
                                <ul>
                                    {station.adresse && <li>{station.adresse}</li>}
                                    {station.telephone && <li>{station.telephone}</li>}
                                </ul>
                            </div>
                        </div>
                        <div className="det_stat_ss_element">
                            <div className='det_stat_ss_top'>
                                <h3>Site</h3>
                            </div>
                            <div className='det_stat_ss_block'>                                
                                <a className='externalLink' href={station.url} target="_blank" rel="noreferrer">
                                    <img src={icon_site} alt='Lien vers site de la station' className='icon_lien' ></img>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                    {station.adresse && <div className="det-map">
                        <SkiStationMap address={station.adresse} name={station.nom} />
                    </div>} 
                </div>
            </section>
            <section className='bloc-rubrique'> 
                <div className='top_class'>
                    <h2>L'incroyable domaine skiable alpin de {station.nom}</h2>
                    <h4>Pistes, remontées mécaniques et plus encore</h4>
                </div>
                    <div className='det_info_station'>
                    <div className="det_stat_info">
                        {(station.hauteur_max && station.hauteur_min) && (
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Hauteur domaine</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <ul>
                                        <li>Hauteur haut : {station.hauteur_max} </li>
                                        <li>Hauteur bas : {station.hauteur_min} </li>
                                        <li>Dénivelé de {station.hauteur_max - station.hauteur_min} mètres</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {(station.nb_piste || station.nb_km_piste || station.nb_piste_verte || 
                        station.nb_piste_bleue || station.nb_piste_rouge || station.nb_piste_noire) && (
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>{station.nb_piste} pistes {station.nb_km_piste && `- ${station.nb_km_piste} kms`}</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    {(station.nb_piste_verte || station.nb_piste_bleue || 
                                    station.nb_piste_rouge || station.nb_piste_noire) && (
                                        <ul className='list_flag_track'>
                                            {station.nb_piste_verte && (
                                                <li><img src={icon_flag} alt='drapeau vert' className='icon_flag_green' ></img>
                                                    {station.nb_piste_verte} pistes vertes</li>
                                            )}
                                            {station.nb_piste_bleue && (
                                                <li><img src={icon_flag} alt='drapeau bleu' className='icon_flag_blue' ></img>
                                                    {station.nb_piste_bleue} pistes bleues</li>
                                            )}
                                            {station.nb_piste_rouge && (
                                                <li><img src={icon_flag} alt='drapeau rouge' className='icon_flag_red' ></img>
                                                    {station.nb_piste_rouge} pistes rouges</li>
                                            )}
                                            {station.nb_piste_noire && (
                                                <li><img src={icon_flag} alt='drapeau noir' className='icon_flag_black' ></img>
                                                    {station.nb_piste_noire} pistes noires</li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        )}
                        {(station.nb_remontee_meca || station.nb_remontee_telepherique || 
                        station.nb_remontee_telecabine || station.nb_remontee_telesiege || 
                        station.nb_remontee_teleski) && (
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>{station.nb_remontee_meca} remontées</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    {(station.nb_remontee_telepherique || station.nb_remontee_telecabine || 
                                    station.nb_remontee_telesiege || station.nb_remontee_teleski) && (
                                        <ul>
                                            {station.nb_remontee_telepherique && (
                                                <li>téléphérique : {station.nb_remontee_telepherique}</li>
                                            )}
                                            {station.nb_remontee_telecabine && (
                                                <li>télécabine : {station.nb_remontee_telecabine}</li>
                                            )}
                                            {station.nb_remontee_telesiege && (
                                                <li>télésiège : {station.nb_remontee_telesiege}</li>
                                            )}
                                            {station.nb_remontee_teleski && (
                                                <li>téléski : {station.nb_remontee_teleski}</li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        )}
                        {station.url_plan_piste && (
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Plan des pistes</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <a className='externalLink' href={station.url_plan_piste} target="_blank" rel="noreferrer">
                                        <img src={icon_map} alt='Lien vers plan des pistes' className='icon_lien' ></img>
                                    </a>
                                </div>
                            </div>
                        )}
                        {station.url_webcam && (
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Webcam</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <a className='externalLink' href={station.url_webcam} target="_blank" rel="noreferrer">
                                        <img src={icon_webcam} alt='Lien vers webcam' className='icon_lien' ></img>
                                    </a>
                                </div>
                            </div>
                        )}
                        {(station.forfait_ski_jour || station.forfait_ski_6jours) && (
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Forfait remontées</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <ul>
                                        {station.forfait_ski_jour && (
                                            <li>journée : {station.forfait_ski_jour} €</li>
                                        )}
                                        {station.forfait_ski_6jours && (
                                            <li>semaine : {station.forfait_ski_6jours} €</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {station.snowpark && (
                            <div className="det_stat_info">
                                <div className="det_stat_ss_element">
                                    <div className='det_stat_ss_top'>
                                        <h3>Autres équipements</h3>
                                    </div>
                                    <div className='det_stat_ss_block'>
                                        <ul> 
                                            <li>Snowpark</li>
                                        </ul>
                                    </div>    
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            {station.domaine &&
                <section className='bloc-rubrique'>
                
                    <div className='top_class'>
                        <h2>Domaine skiable</h2>
                    </div>
                    <div className='det_info_label'>
                        <div className="det_stat_info">
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Domaine</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <ul>
                                        <li><div className="internalLink"><Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?domaines=${station.domaine}`} key={station.domaine}>{station.domaine}</Link></div> </li>
                                    </ul>
                                </div>
                            </div>
                            {station.domaine_nb_km_piste && (<div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Nombre de pistes</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <ul>
                                        {station.domaine_nb_km_piste && (
                                            <li>{station.domaine_nb_km_piste}</li>
                                        )}
                                    </ul>
                                </div>
                               
                            </div>
                          )}   
                           {station.domaine_liaison && (<div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Type de liaison</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <ul>
                                        {station.domaine_liaison && (
                                            <li>{station.domaine_liaison}</li>
                                        )}
                                    </ul>
                                </div>
                               
                            </div>
                          )}   
                        </div>
                    </div>
                
                </section>
            }

 
            {(station.centrale_resa_nom || station.centrale_resa_tel || station.centrale_resa_mail || station.centrale_resa_url || station.location_tarif_studio || station.location_tarif_appartement) && (
                <section className='bloc-rubrique'>
                    <div className='top_class'>
                        <h2>Trouvez votre hébergement idéal à {station.nom} </h2>
                        <h4>Appartements, hôtels et chalets pour tous les budgets</h4>
                    </div>
                    
                        <div className='det_info_label'>
                            <div className="det_stat_info">
                                {(station.centrale_resa_nom || station.centrale_resa_tel ) && (
                                <div className="det_stat_ss_element">
                                    <div className='det_stat_ss_top'>
                                      <h3>Centrale de réservation</h3>
                                    </div>
                                    <div className='det_stat_ss_block'>
                                        <ul> 
                                            {station.centrale_resa_nom && 
                                                <li>{station.centrale_resa_nom}</li>
                                            }
                                            {station.centrale_resa_tel && 
                                                <li>{station.centrale_resa_tel}</li>
                                            }                                            
                                        </ul>
                                    </div>    
                                </div>
                                )}
                                {(station.centrale_resa_mail) && (
                                <div className="det_stat_ss_element">
                                    <div className='det_stat_ss_top'>
                                      <h3>Centrale de réservation</h3>
                                    </div>
                                    <div className='det_stat_ss_block'>
                                        <ul> 
                                            {station.centrale_resa_mail && 
                                                <li>{station.centrale_resa_mail}</li>
                                            }
                                        </ul>
                                    </div>    
                                </div>
                                )}
                                {(station.centrale_resa_url) && (
                                <div className="det_stat_ss_element">
                                    <div className='det_stat_ss_top'>
                                      <h3>Centrale de réservation</h3>
                                    </div>
                                    <div className='det_stat_ss_block'>
                                        <a className='externalLink' href={station.centrale_resa_url} target="_blank" rel="noreferrer">
                                            <img src={icon_site} alt='Lien vers site de la station' className='icon_lien' ></img>
                                        </a>
                                    </div>    
                                </div>
                                )}
                            </div>   
                        </div>
                    
                    {(station.location_tarif_studio || station.location_tarif_appartement) && (
                        <div className='det_info_label'>
                            <div className="det_stat_info">
                                <div className="det_stat_ss_element">
                                    <div className='det_stat_ss_top'>
                                      <h3>Tarif location</h3>
                                    </div>
                                    <div className='det_stat_ss_block'>
                                        <ul> 
                                            {station.location_tarif_studio && (
                                                <li>studio : {station.location_tarif_studio}</li>
                                            )}
                                            {station.location_tarif_appartement && (
                                                <li>appartement : {station.location_tarif_appartement}</li>
                                            )}
                                        </ul>
                                    </div>    
                                </div>
                            </div>   
                        </div>
                    )}
                </section>
            )}
            
                {(station.esf_nom_ecole || station.esf_nb_moniteur || station.esf_url_reservation || 
                  station.esf_adresse || station.esf_service) && (
                    <section className='bloc-rubrique'>      
                        <div className='top_class'>
                            <h2>Apprenez à skier à {station.nom}  </h2>
                            <h4>Notre école de ski pour tous les niveaux</h4>
                        </div>
                        <div className='det_info_label'>
                            <div className="det_stat_info">
                                {(station.esf_nom_ecole) && (
                                    <div className="det_stat_ss_element">
                                        <div className='det_stat_ss_top'>
                                             <h3>Nom de l'école</h3>
                                        </div>
                                        <div className='det_stat_ss_block'>
                                             <ul> 
                                                {station.esf_nom_ecole && (
                                                    <li> {station.esf_nom_ecole}</li>
                                                )}
                                             </ul>
                                        </div>    
                                    </div>
                                )}
                                {(station.esf_nb_moniteur) && (
                                    <div className="det_stat_ss_element">
                                        <div className='det_stat_ss_top'>
                                             <h3>Nombre de moniteurs</h3>
                                        </div>
                                        <div className='det_stat_ss_block'>
                                             <ul> 
                                                {station.esf_nb_moniteur && (
                                                    <li>{station.esf_nb_moniteur}</li>
                                                )}
                                             </ul>
                                        </div>    
                                    </div>
                                )}
                                {(station.esf_url_reservation) && (
                                    <div className="det_stat_ss_element">
                                        <div className='det_stat_ss_top'>
                                             <h3>Réservation</h3>
                                        </div>
                                        <div className='det_stat_ss_block'>
                                            <a className='externalLink' href={station.esf_url_reservation} target="_blank" rel="noreferrer">
                                                <img src={icon_site} alt='Lien vers ESF' className='icon_lien' ></img>
                                            </a>
                                        </div>    
                                    </div>
                                )}
                                 {station.esf_adresse && (
                                    <div className="det_stat_ss_element">
                                        <div className='det_stat_ss_top'>
                                             <h3>Localisation ESF</h3>
                                        </div>
                                        <div className='det_stat_ss_block'>
                                             <ul> 
                                                {station.esf_adresse && (
                                                    <li>{station.esf_adresse}</li>
                                                )}
                                             </ul>
                                        </div>    
                                    </div>
                                )}
                                {station.esf_service && (
                                    <div className="det_stat_ss_element">
                                        <div className='det_stat_ss_top'>
                                             <h3>ESF Services</h3>
                                        </div>
                                        <div className='det_stat_ss_block'>
                                             <ul> 
                                                {station.esf_service && (
                                                    <li>{station.esf_service}</li>
                                                )}
                                             </ul>
                                        </div>    
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                )}
            
                {(station.distance_paris_km || station.distance_paris_seconde || station.train_gare) && (
                    <section className='bloc-rubrique'>      
                            <div className='top_class'>
                                <h2>Comment venir à {station.nom} ? </h2>
                                <h4>Toutes les options pour votre arrivée en station</h4>
                            </div>
                            <div className='det_info_label'>
                        <div className="det_stat_info">
                            {(station.distance_paris_km || station.distance_paris_seconde) && (
                                <div className="det_stat_ss_element">
                                    <div className='det_stat_ss_top'>
                                      <h3>Venir en voiture</h3>
                                    </div>
                                    <div className='det_stat_ss_block'>
                                        <ul> 
                                            {station.distance_paris_km && (
                                                <li>Distance depuis Paris : {station.distance_paris_km} kms</li>
                                            )}
                                            {station.distance_paris_seconde && (
                                                <li>Durée depuis Paris : {station.distance_paris_seconde}</li>
                                            )}
                                        </ul>
                                    </div>    
                                </div>
                            )}
                            {(station.train_gare) && (
                                <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                  <h3>Venir en train</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <ul> 
                                        {station.train_gare && (
                                            <li>Gare la plus proche : {station.train_gare}</li>
                                        )}
                                        {station.train_navette && (
                                            <li>Navette en bus :  {station.train_navette}</li>
                                        )}
                                    
                                        </ul>
                                    </div>    
                                </div>
                            )}
                        </div>
                            
                    </div>
                </section>
            )}

            
            <section className='bloc-rubrique'>      
                <div className='top_class'>
                    <h2>Découvrez le domaine skiable nordique de {station.nom}</h2>
                    <h4>Randonnée, ski de fond et activités hivernales</h4>
                </div>
                <div className='det_info_label'>
                    <div className="det_stat_info">
                        <div className="det_stat_ss_element">
                            <div className='det_stat_ss_top'>
                                <h3>Domaine</h3>
                            </div>
                            <div className='det_stat_ss_block'>

                            </div>
                        </div>
                    </div>
                </div>
                    
            </section>
            
            {(station.app_apple_link || station.app_android_link || station.piscine) && (
                <section className='bloc-rubrique'>
                    <div className='top_class'>
                        <h2>Consultez les informations et services disponibles à {station.nom}</h2>
                    </div>
               
                    <div className='det_info_label'>
                        <div className="det_stat_info">
                            {station.app_apple_link && (
                                <div className="det_stat_ss_element">
                                    <div className='det_stat_ss_top'>
                                        <h3>Application Iphone</h3>
                                    </div>
                                    <div className='det_stat_ss_block'>
                                        <ul> 
                                            {station.app_apple_link && (
                                                <li>
                                                    <a href={station.app_apple_link} target="_blank" rel="noreferrer">
                                                        <img src={logo_iphone} alt='Lien vers webcam' className='icon_lien' ></img>
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>    
                                </div>
                            )}
                            
                            {station.app_android_link && (
                                <div className="det_stat_ss_element">
                                    <div className='det_stat_ss_top'>
                                        <h3>Application Android</h3>
                                    </div>
                                    <div className='det_stat_ss_block'>
                                        <ul> 
                                        {station.app_android_link && (
                                            <li>
                                            <a href={station.app_android_link} target="_blank" rel="noreferrer">
                                                <img src={logo_android} alt='Lien vers webcam' className='icon_lien' ></img>
                                            </a>
                                            </li>
                                        )}
                                        </ul>
                                    </div>       
                                </div>
                            )}
                            {station.piscine && (
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Equipements</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <ul>
                                    {station.piscine && (
                                        <li>Piscine</li>
                                    )}
                                    </ul>
                                </div>    
                            </div>
                            )}
                        </div>

                        
                    </div>
                </section>
            )}

            {station.label_station &&
                <section className='bloc-rubrique'>
                    <div className='top_class'>
                        <div className='det_stat_ss_top'></div>
                        <h2>{station.nom}, station labellisée Famille Plus</h2>
                        <h4>pour des vacances en famille réussies</h4>
                    </div>
                    
                    <div className='det_info_txt'>
                         {station.label_fam_services}
                    </div>  

                    <div className='det_info_label'>
                        <div className="det_stat_info">
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Famille Plus</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <ul>
                                        <li>
                                            <i>
                                                <img src={logo_famille_plus} alt='logo association famille plus' />
                                            </i>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                           
                            
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Liens vers la station</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <a className='externalLink' href={station.label_fam_url_ext} target="_blank" rel="noreferrer">
                                        <img src={icon_site} alt='Lien vers site de la station - Famille Plus' className='icon_lien' ></img>
                                    </a>
                                    
                                </div>
                            </div>
                            <div className="det_stat_ss_element">
                                <div className='det_stat_ss_top'>
                                    <h3>Lien vers Famille Plus</h3>
                                </div>
                                <div className='det_stat_ss_block'>
                                    <a className='externalLink' href={station.label_fam_url_int} target="_blank" rel="noreferrer">
                                        <img src={icon_site} alt='Lien vers site de la station - Famille Plus' className='icon_lien' ></img>
                                    </a>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                  
                </section>
            }
        </div>
        </div>
        <Footer />  
    </div>
}
