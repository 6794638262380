import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import icon_cloud from './../assets/icon/homepage-cloud-database-48.png';
import icon_filter from './../assets/icon/homepage-filter-48.png';
import icon_route from './../assets/icon/homepage-route-48.png';
import icon_speed from './../assets/icon/homepage-speed-48.png';
import photo_vertical from './../assets/mountain_vertical.webp';
import icon_mountain from './../assets/icon/homepage-mountain-64.png';
import icon_mountain_city from './../assets/icon/homepage-mountain-city-60.png';
import icon_skier from './../assets/icon/homepage-skier-60.png';
import photo_alpes from './../assets/discover-alpes.webp';
import photo_pyrenees from './../assets/discover-pyrenees.webp';
import photo_vosges from './../assets/discover-vosges.webp';
import photo_massif_central from './../assets/discover-massif-central.webp';
import photo_jura from './../assets/discover-jura.webp';


const Home = () => {

    let cannonicalLink = "https://www.monchamois.com/"
    let metaDescription = "Comparez les meilleures stations de ski en France et trouvez celle qui correspond parfaitement à vos envies. Services, pistes, hébergements : accédez à toutes les informations pour choisir votre prochaine destination ski.";

    return (
    <div className='home'>
        <Helmet>
            <title>Trouvez la Station de Ski Idéale</title>
            <meta name="description" content={metaDescription}></meta>
             <link rel="canonical" href={cannonicalLink}></link>
        </Helmet>
    <header className='header'>
     <div className='background'>
      <Navbar />
      <div className='header-textbox'>
        <div className='header-texbox-title'>
            <h1>Trouvez la Station de Ski Idéale</h1>
        </div>
        <div className='header-texbox-block-text'>
            <div className='header-texbox-block-text-left'>
                <p>Vous cherchez la destination parfaite pour vos vacances au ski cet hiver ?</p>
            </div>
            <div className='header-texbox-block-text-right'>
                <p>Notre comparateur de stations de ski en France vous aide à trouver le lieu idéal selon vos préférences et votre budget.</p>
                <p>Comparez les stations sur différents critères et réservez en toute confiance.</p>
            </div>
        </div>
        <div className='header-texbox-block-url'>
             <Link to= "/comparer-et-trouver-votre-station-de-ski-ideale" 
                className='hero-btn'
                 aria-label="Accéder a notre fabuleux comparateur de station de ski">
              Commencer à Comparer</Link>
        </div>
      </div>
    </div>
   </header>
   <section className='section-fonction'>
      <div className="fonction-block-center">
        <div className="fonction-block-image">
                <img src={photo_vertical}  alt="mont blanc en arriere plan"></img>
        </div>
        <div className="fonction-block-text">
            <h2 className="mb-4">Pourquoi Utiliser Notre Comparateur de Stations de Ski ?</h2>
            <ul className='fonction-block-list'>

            <li className='fonction-block-list-element'>
                <div className='fonction-list-img'> <img src={icon_cloud}  alt="icone cloud database"></img></div> 
                <div className='fonction-list-data'>
                    <div className='fonction-list-data-title'>Base de Données Complète</div>
                    <div className='fonction-list-data-text'>Accédez à une base de données exhaustive couvrant toutes les stations de ski en France, avec des informations détaillées et mises à jour régulièrement.</div>
                </div>
            </li>
            <li className='fonction-block-list-element'>
                <div className='fonction-list-img'><img src={icon_speed}  alt="icone compteur de vitesse rapide"></img></div>
                <div className='fonction-list-data'> 
                    <div className='fonction-list-data-title'>Comparaison Facile et Rapide</div>
                    <div className='fonction-list-data-text'>Comparez rapidement les stations de ski sur des critères essentiels tels que les prix des forfaits, les types de pistes, et les services offerts.</div>
                </div>
            </li>
            <li className='fonction-block-list-element'>
                <div className='fonction-list-img'><img src={icon_filter}  alt="icone curseur de reglages"></img></div>
                <div className='fonction-list-data'>
                    <div className='fonction-list-data-title'>Filtrage Avancé</div>
                    <div className='fonction-list-data-text'>Trouvez la station idéale en utilisant des filtres avancés pour trier par région, niveau de difficulté des pistes, prix, et bien plus.</div>
                </div> 
            </li>
            <li className='fonction-block-list-element'>
                <div className='fonction-list-img'><img src={icon_route}  alt="icone carte"></img></div>
                <div className='fonction-list-data'>
                    <div className='fonction-list-data-title'>Itinéraires et Transports</div>
                    <div className='fonction-list-data-text'>Choisissez les meilleurs itinéraires et options de transport pour vous rendre facilement à votre station de ski choisie.</div>
                </div> 
            </li>   
            </ul>

        </div>
  </div>
</section>   
<section className="section-key-number">
    <div className='section-key-number-grp'>
    <div className='section-key-number-grp-title'>
        <h2>Chiffres Clés des Stations de Ski en France </h2>
    </div>   
        <div className='grp-key-number-block'>
            <div className='grp-key-number'>
                <div className='key-number'>
                    <div className='key-number-icon'><img src={icon_mountain}  alt="icone cloud database"></img></div>
                    <div className='key-number-text'>5 Massifs</div>
                </div>
            </div>
            <div className='grp-key-number'>
                <div className='key-number'>
                    <div className='key-number-icon'><img src={icon_mountain_city}  alt="icone cloud database"></img></div>
                    <div className='key-number-text'>300 Stations</div>
                </div>
            </div><div className='grp-key-number'>
                <div className='key-number'>
                    <div className='key-number-icon'><img src={icon_skier}  alt="icone cloud database"></img></div>
                    <div className='key-number-text'>4 000 Km de pistes</div>
                </div>
            </div>
        </div>   
    </div>
  </section>
  <section className='section-discover-massif'>
    <div className='discover-massif-block'>
        <div className='discover-massif-title'>
            <h2>Découvrir Les Massifs</h2>
        </div>
        <div className='discover-massif-group'>
            <div className='discover-massif-element'>
                <div className='discover-massif-element-img'><img src={photo_alpes}  alt="mont blanc en arriere plan"></img></div>
                <div className='discover-massif-element-text'>
                    <div className='discover-massif-element-title'>Alpes</div>
                    <div className='discover-massif-element-trait'> </div>
                    <div className='discover-massif-element-nbstation'>230 Stations</div>
                    <div className='discover-massif-element-link'>
                        <Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?regions=1%2C2`}
                            key='alpes'>Découvrir ce massif
                        </Link> 
                    </div>
                </div>
            </div>
            <div className='discover-massif-element'>
                <div className='discover-massif-element-img'><img src={photo_pyrenees}  alt="mont blanc en arriere plan"></img></div>
                <div className='discover-massif-element-text'>
                    <div className='discover-massif-element-title'>Pyrénées</div>
                    <div className='discover-massif-element-trait'> </div>
                    <div className='discover-massif-element-nbstation'>38 Stations</div>
                    <div className='discover-massif-element-link'>
                        <Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?regions=6`}
                            key='pyrenees'>Découvrir ce massif
                        </Link> 
                    </div>
                </div>
            </div>
            <div className='discover-massif-element'>
                <div className='discover-massif-element-img'><img src={photo_massif_central}  alt="mont blanc en arriere plan"></img></div>
                <div className='discover-massif-element-text'>
                    <div className='discover-massif-element-title'>Massif Central</div>
                    <div className='discover-massif-element-trait'> </div>
                    <div className='discover-massif-element-nbstation'>21 Stations</div>
                    <div className='discover-massif-element-link'>
                        <Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?regions=5`}
                            key='massif_central'>Découvrir ce massif
                        </Link> 
                    </div>
                </div>
            </div>
            <div className='discover-massif-element'>
                <div className='discover-massif-element-img'><img src={photo_vosges}  alt="mont blanc en arriere plan"></img></div>
                <div className='discover-massif-element-text'>
                    <div className='discover-massif-element-title'>Vosges</div>
                    <div className='discover-massif-element-trait'> </div>
                    <div className='discover-massif-element-nbstation'>20 Stations</div>
                    <div className='discover-massif-element-link'>
                        <Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?regions=7`}
                            key='vosges'>Découvrir ce massif
                        </Link> 
                    </div>
                </div>
            </div>
            <div className='discover-massif-element'>
                <div className='discover-massif-element-img'><img src={photo_jura}  alt="mont blanc en arriere plan"></img></div>
                <div className='discover-massif-element-text'>
                    <div className='discover-massif-element-title'>Jura</div>
                    <div className='discover-massif-element-trait'> </div>
                    <div className='discover-massif-element-nbstation'>17 Stations</div>
                    <div className='discover-massif-element-link'>
                        <Link to={`/comparer-et-trouver-votre-station-de-ski-ideale?regions=4`}
                            key='vosges'>Découvrir ce massif
                        </Link> 
                    </div>
                </div>
            </div>
        </div>
     </div>    
  </section>
  <Footer />  
    </div>
    
    );
};

export default Home;